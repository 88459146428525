import { createSlice } from '@reduxjs/toolkit';

// utils
import axios, { endpoints } from '../../utils/axios'; // import dispatch from store
import marketplaceConnection from './integrations';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  marketplaces: [],
  ebayCategories: [],
  ebayRequiredFields: [],
  ebayOptionalFields: [],
  ebayPoliciesAndLocations: [],
  etsyCategories: [],
  etsyRequiredFields: [],
  etsyOptionalFields: [],
  etsyShippingProfiles: []

};

const slice = createSlice({
  name: 'managment',
  initialState,
  reducers: {
    // START LOADING
    startLoading (state) {
      state.isLoading = true;
    },
    // END LOADING
    endLoading (state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError (state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // RESET ERROR
    resetError (state, action) {
      state.isLoading = false;
      state.error = null;
    },

    // GET MARKETPLACES
    getMarketplacesSuccess (state, action) {
      const marketplaces = action.payload;
      state.marketplaces = marketplaces;
      state.isLoading = false;
    },

    // GET EBAY CATEGORIES
    getEbayCategoriesSuccess (state, action) {
      const ebayCategories = action.payload;
      state.ebayCategories = ebayCategories;
      state.isLoading = false;
    },

    // GET EBAY REQUIRED FIELDS
    getEbayCategoryRequiredFieldsSuccess (state, action) {
      const ebayRequiredCategoryFields = action.payload;
      state.ebayRequiredFields = ebayRequiredCategoryFields;
      state.isLoading = false;
    },
    // GET EBAY OPTIONAL FIELDS
    getEbayCategoryOptionalFieldsSuccess (state, action) {
      const ebayOptionalCategoryFields = action.payload;
      state.ebayOptionalFields = ebayOptionalCategoryFields;
      state.isLoading = false;
    },


    // GET ETSY CATEGORIES
    getEtsyCategoriesSuccess (state, action) {
      const etsyCategories = action.payload;
      state.etsyCategories = etsyCategories;
      state.isLoading = false;
    },


    // GET ETSY REQUIRED FIELDS
    getEtsyCategoryRequiredFieldsSuccess (state, action) {
      const etsyCategoryRequiredFields = action.payload;
      state.etsyRequiredFields = etsyCategoryRequiredFields;
      state.isLoading = false;
    },


    // GET ETSY CATEGORY FIELDS
    getEtsyCategoryOptionalFieldsSuccess (state, action) {
      const etsyCategoryOptionalFields = action.payload;
      state.etsyOptionalFields = etsyCategoryOptionalFields;
      state.isLoading = false;
    },

    // GET EBAY POLICIES AND LOCATIONS
    getEbayPoliciesAndLocationsSuccess (state, action) {
      const ebayPoliciesAndLocations = action.payload;
      state.ebayPoliciesAndLocations = ebayPoliciesAndLocations;
      state.isLoading = false;
    },

    // GET ETSY SHIPPING PROFILES
    getEtsyShippingProfilesSuccess (state, action) {
      const etsyShippingProfiles = action.payload;
      state.etsyShippingProfiles = etsyShippingProfiles;
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;


// ----------------------------------------------------------------------

export function getMarketplaces () {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const URL = endpoints?.management?.marketplaces;
    const { data } = await axios.get(URL);
    const marketplaces = data?.map((marketplace) => ({
      ...marketplace,
      image: `/assets/icons/app/${marketplace?.platform?.toLowerCase()}.svg`,
      action: marketplaceConnection[marketplace?.platform],
      revokeIntegration: () => marketplaceConnection.revokeIntegration(marketplace?.name),
    }));

    dispatch(slice.actions.getMarketplacesSuccess(marketplaces));
  };
}

// ----------------------------------------------------------------------
export function getEbayCategories () {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const URL = endpoints.management.ebay.category;
    const { data } = await axios.get(URL);

    if (Array.isArray(data) && data?.length !== 0) {
      const listValue = data?.map((x) => createEbayCategoriesObject(x))
      dispatch(slice.actions.getEbayCategoriesSuccess(listValue));

    } else {
      dispatch(slice.actions.getEbayCategoriesSuccess([]));
    }

  };
}

// ----------------------------------------------------------------------
export function getEbayCategoryFields (category_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    if (!category_id) {
      dispatch(slice.actions.getEbayCategoryRequiredFieldsSuccess([]));
      dispatch(slice.actions.getEbayCategoryOptionalFieldsSuccess([]));
      return;
    }

    const res = await axios.post(endpoints.management.ebay.categoryFields, {
      category_id
    });
    const { requiredFields, optionalFields } = getCategoryFieldsProperties("ebay", res?.data)

    dispatch(slice.actions.getEbayCategoryRequiredFieldsSuccess(requiredFields));
    dispatch(slice.actions.getEbayCategoryOptionalFieldsSuccess(optionalFields));

  };
}

// ----------------------------------------------------------------------
export function getEtsyCategories () {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const res = await axios.get(`${endpoints.management.etsy.category}`);


    if (Array.isArray(res.data) && res.data?.length !== 0) {
      const listValue = res.data?.map((x) => createEtsyCategoriesObject(x))
      dispatch(slice.actions.getEtsyCategoriesSuccess(listValue));

    } else {
      dispatch(slice.actions.getEtsyCategoriesSuccess([]));
    }


  };
}

// ----------------------------------------------------------------------
export function getEtsyCategoryFields (category_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    if (!category_id) {
      dispatch(slice.actions.getEtsyCategoryRequiredFieldsSuccess([]));
      dispatch(slice.actions.getEtsyCategoryOptionalFieldsSuccess([]));
      return;
    }

    const res = await axios.get(`${endpoints.management.etsy.categoryFields}/${category_id}`);

    const { requiredFields, optionalFields } = getCategoryFieldsProperties("etsy", res?.data)
    dispatch(slice.actions.getEtsyCategoryRequiredFieldsSuccess(requiredFields));
    dispatch(slice.actions.getEtsyCategoryOptionalFieldsSuccess(optionalFields));


  };
}

// ----------------------------------------------------------------------
export function getEbayPoliciesAndLocations () {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const res = await axios.get(`${endpoints.management.ebay.policies}`)
    dispatch(slice.actions.getEbayPoliciesAndLocationsSuccess(res.data));
  };
}

// ----------------------------------------------------------------------
export function getEtsyShippingProfiles () {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const res = await axios.get(`${endpoints.management.etsy.shipping}`)
    dispatch(slice.actions.getEtsyShippingProfilesSuccess(res.data));
  };
}

// ----------------------------------------------------------------------
export function createSettings (settings) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(endpoints.management.settings, settings);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}
//----------------------------------------------------------------------
export function resetError () {
  return async (dispatch) => {
    dispatch(slice.actions.resetError());
  };
}

//----------------------------------------------------------------------
const createEbayCategoriesObject = (x) => x.childCategoryTreeNodes ?
  {
    title: x?.category?.categoryName,
    value: x?.category?.categoryId,
    isLeaf: x.leafCategoryTreeNode,
    children: [
      ...x.childCategoryTreeNodes.map(
        y => createEbayCategoriesObject(y)
      )
    ]
  } : {
    label: x?.category?.categoryName,
    value: x?.category?.categoryId,
    isLeaf: x.leafCategoryTreeNode
  };


// ----------------------------------------------------------------------
const getCategoryFieldsProperties = (marketplace, list) => {
  let requiredFields = [];
  let optionalFields = [];

  if (marketplace === "etsy") {
    requiredFields = list?.filter(x => x.is_required);
    optionalFields = list?.filter(x => !x.is_required && x.supports_attributes)
  }

  if (marketplace === "ebay") {
    if (Array.isArray(list)) {
      requiredFields = list?.filter(x => x.aspectConstraint.aspectRequired)
      optionalFields = list?.filter(x => !x.aspectConstraint.aspectRequired)
    }
  }

  return {
    requiredFields,
    optionalFields
  }
}
// ----------------------------------------------------------------------
const createEtsyCategoriesObject = (x) => x.children.length > 0 ?
  {
    title: x.name,
    value: x.id,
    isLeaf: false,
    children: [
      ...x.children.map(
        y => createEtsyCategoriesObject(y)
      )
    ]
  } : {
    label: x.name,
    value: x.id,
    isLeaf: true
  };
