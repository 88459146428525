// import axios from 'axios';

import axios, { endpoints } from 'src/utils/axios';

// ----------------------------------------------------------------------


async function connectEbay (marketplaceId) {
  const URL = `${endpoints.auth.ebay}/${marketplaceId}`
  const ebayUrl = (await axios.get(URL)).data?.url;
  window.location.href = ebayUrl;

}

async function connectShopify (shop, userId) {
  const authUrl = `${endpoints.auth.shopify}?shop=${shop}&userId=${userId}`;
  window.location.href = authUrl;
  // await axios.get(authUrl);
}


async function connectEtsy () {
  const URL = endpoints.auth.etsy;
  const etsyAuthUrl = (await axios.get(URL)).data?.url;
  window.location.href = etsyAuthUrl;
}

async function connectWooCommerce (storeUrl, consumerKey, consumerSecret) {
  const URL = endpoints.auth.wooCommerce;
  const res = await axios.post(URL, { storeUrl, consumerKey, consumerSecret });
  window.location.reload();
}

const connectBigCommerce = async (email) => {
  const URL = endpoints?.auth?.bigCommerce;
  const res = await axios.post(URL, { email });

  window.location.href = res?.data?.appUrl;
}

async function revokeIntegration (name) {
  const URL = endpoints.auth.revoke;

  await axios.delete(URL, { data: { name } });
  window.location.reload();
}


export const getEbayApplicationAccessToken = async () => {
  const URL = `http://localhost:5000/api/shopify/auth`

  // set no cors mode 
  const res = await fetch(URL, {
    method: 'GET',
    mode: 'no-cors',
    headers: {
      'Content-Type': 'application/json',
    }
  }).then((x) => x.json()).catch((err) => console.log(err));

  return res;
}



const marketplaceConnection = {
  ebay: connectEbay,
  etsy: connectEtsy,
  shopify: connectShopify,
  wooCommerce: connectWooCommerce,
  bigCommerce: connectBigCommerce,
  revokeIntegration,
};

export default marketplaceConnection;