import React from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import ErrorView from 'src/sections/error/error-view';

export default function ErrorBoundary ({ children }) {
  // const { enqueueSnackbar } = useSnackbar();
  // const [error, setError] = useState(null);


  // const handleError = (e) => {
  //   console.log('error__________Z', e);

  //   enqueueSnackbar(`${e?.message}`, { variant: 'error' });
  //   setError(e?.message || e);
  // };


  return (
    <ReactErrorBoundary

      // onError={handleError}
      fallbackRender={({ error }) => (
        <ErrorView error={error?.message || 'Something went wrong!'} />
      )}
    >
      {children}
    </ReactErrorBoundary>
  );
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};