// ----------------------------------------------------------------------

export const PRODUCT_GENDER_OPTIONS = [
  { label: 'Men', value: 'M' },
  { label: 'Women', value: 'W' },
  { label: 'Kids', value: 'K' },
  { label: 'Unisex', value: 'U' },
];

export const PRODUCT_CATEGORY_OPTIONS = ['Shose', 'Apparel', 'Accessories'];

export const PRODUCT_RATING_OPTIONS = ['up4Star', 'up3Star', 'up2Star', 'up1Star'];

export const PRODUCT_COLOR_OPTIONS = [
  '#00AB55',
  '#000000',
  '#FFFFFF',
  '#FFC0CB',
  '#FF4842',
  '#1890FF',
  '#94D82D',
  '#FFC107',
];

export const PRODUCT_COLOR_NAME_OPTIONS = [
  { value: 'red', label: 'Red' },
  { value: 'blue', label: 'Blue' },
  { value: 'cyan', label: 'Cyan' },
  { value: 'green', label: 'Green' },
  { value: 'yellow', label: 'Yellow' },
  { value: 'violet', label: 'Violet' },
  { value: 'black', label: 'Black' },
  { value: 'white', label: 'White' },
];

export const PRODUCT_SIZE_OPTIONS = [
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '8.5', label: '8.5' },
  { value: '9', label: '9' },
  { value: '9.5', label: '9.5' },
  { value: '10', label: '10' },
  { value: '10.5', label: '10.5' },
  { value: '11', label: '11' },
  { value: '11.5', label: '11.5' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
];

export const PRODUCT_STOCK_OPTIONS = [
  { value: 'in stock', label: 'In stock' },
  { value: 'low stock', label: 'Low stock' },
  { value: 'out of stock', label: 'Out of stock' },
];

export const PRODUCT_PUBLISH_OPTIONS = [
  {
    value: 'published',
    label: 'Published',
  },
  {
    value: 'draft',
    label: 'Draft',
  },
];

export const PRODUCT_SORT_OPTIONS = [
  { value: 'featured', label: 'Featured' },
  { value: 'newest', label: 'Newest' },
  { value: 'priceDesc', label: 'Price: High - Low' },
  { value: 'priceAsc', label: 'Price: Low - High' },
];

export const PRODUCT_CATEGORY_GROUP_OPTIONS = [
  {
    group: 'Clothing',
    classify: ['Shirts', 'T-shirts', 'Jeans', 'Leather', 'Accessories'],
  },
  {
    group: 'Tailored',
    classify: ['Suits', 'Blazers', 'Trousers', 'Waistcoats', 'Apparel'],
  },
  {
    group: 'Accessories',
    classify: ['Shoes', 'Backpacks and bags', 'Bracelets', 'Face masks'],
  },
];


export const PRODUCT_CONDITION_OPTIONS = [

  { value: null, label: null },
  { value: 'NEW', label: 'New' },
  { value: 'LIKE_NEW', label: 'Like New' },
  { value: 'NEW_WITH_DEFECTS', label: 'New with Defects' },
  { value: 'USED_EXCELLENT', label: 'Used - Excellent' },
  { value: 'USED_VERY_GOOD', label: 'Used - Very Good' },
  { value: 'USED_GOOD', label: 'Used - Good' },
  { value: 'USED_ACCEPTABLE', label: 'Used - Acceptable' }
];

export const PRODUCT_PACKAGE_TYPE = [
  { value: null, label: null },
  { value: 'MAILING_BOX', label: 'Mailing Box' },
  { value: 'PADDED_BAGS', label: 'Padded Bags' },
  { value: 'PARCEL_OR_PADDED_ENVELOPE', label: 'Parcel or Padded Envelope' },
  { value: 'LARGE_ENVELOPE', label: 'Large Envelope' },
  { value: 'LETTER', label: 'Letter' },
  { value: 'PACKAGE_THICK_ENVELOPE', label: 'Thick Envelope' },
  { value: 'USPS_FLAT_RATE_ENVELOPE', label: 'USPS Flat Rate Envelope' },
  { value: 'USPS_LARGE_PACK', label: 'USPS Large Pack' },
  { value: 'VERY_LARGE_PACK', label: 'Very Large Pack' },
  { value: 'EXPANDABLE_TOUGH_BAGS', label: 'Expandable Tough Bags' },
  { value: 'EXTRA_LARGE_PACK', label: 'Extra Large Pack' },
  { value: 'ROLL', label: 'Roll' },
  { value: 'UPS_LETTER', label: 'UPS Letter' },
];

export const PRODUCT_CHECKOUT_STEPS = ['Cart', 'Billing & address', 'Payment'];


export const ETSY_WHO_DID = [
  { value: null, label: null },
  { value: 'i_did', label: 'I did' },
  { value: 'collective', label: 'A member of my shop' },
  { value: 'someone_else', label: 'Another company or person' },
];

export const ETSY_WHEN_MADE = [
  { value: null, label: null },
  { value: 'made_to_order', label: 'Made to order' },
  { value: '2020_2024', label: '2020 - 2024' },
  { value: '2010_2019', label: '2010 - 2019' },
  { value: '2005_2009', label: '2005 - 2009' },
  // { value: 'before_2005', label: '1990 - 1999' },
  { value: '2000_2004', label: '2000 - 2004' },
  { value: '1990s', label: '1990s' },
  { value: '1980s', label: '1980s' },
  { value: '1970s', label: '1970s' },
  { value: '1960s', label: '1960s' },
  { value: '1950s', label: '1950s' },
  { value: '1940s', label: '1940s' },
  { value: '1930s', label: '1930s' },
  { value: '1920s', label: '1920s' },
  { value: '1910s', label: '1910s' },
  { value: '1900s', label: '1900s' },
  { value: '1800s', label: '1800s' },
  { value: '1700s', label: '1700s' },
  { value: 'before_1700', label: 'Before 1700' },
];

export const ETSY_TYPE = [
  { value: null, label: null },
  { value: 'physical', label: 'Physical' },
  { value: 'digital', label: 'Digital' },
  { value: 'supply', label: 'Supply' },
];

