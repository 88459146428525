// src/NotificationContext.js
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import React, { useMemo, useEffect, createContext } from 'react';

import socket from './socket';

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // Listen for notifications from the server
    socket.on('notification', (data) => {
      const { message, type } = data;
      enqueueSnackbar(message, {
        variant: type, // You can customize the variant
        autoHideDuration: 3000, // Duration to show the snackbar
      });
    });

    // Clean up the effect
    return () => {
      socket.off('notification');
    };
  }, [enqueueSnackbar]);

  // Memoize the context value to prevent re-renders
  const contextValue = useMemo(() => ({}), []); // You can add values if needed

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
};

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};